import React from 'react';
import { DefaultLottieOptions } from './constants';
import Lottie from 'react-lottie';
const Ball = ({ code, animationData }) => {
    // console.log('Lazy Ball...  ', { code, animationData });
    return (
        <Lottie options={{ ...DefaultLottieOptions, animationData }} />
    )
}
export default Ball;
